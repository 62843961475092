//@import url("../css/line-awesome.min.css");

.big-title { 
    background: url('../images/texure.jpg'); 
    background-repeat: repeat-x; 
    background-position: 100% 100%; 
    color: transparent; 
    -webkit-font-smoothing: antialiased; 
    -webkit-background-clip: text; 
    -moz-background-clip: text; 
    background-clip: text; 
    -webkit-text-fill-color: transparent; 
}

.main-h1 {
    font-size: 4.209em;
}

.main-h2 {
    font-size: 3.157em;
}

.main-h3 {
    font-size: 2.369em;
}

.main-h5 {
    font-size: 1.333em;
}

.main-h1, .main-h2, .main-h3, .main-h4, .main-h5, .main-h6 {
    font-weight: 500;
    margin: 0;
    line-height: 1.2;
    color: #fff;
}



header#main-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    z-index: 999;
    background: rgba(20,20,20,.5)!important;
    // -webkit-backdrop-filter: blur(10px);
    // backdrop-filter: blur(10px);
}

img.logo { width: 160px;  }

.menu-main-menu-container{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
}

@media (min-width: 992px) {
    .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .main-container-fluid {
        padding: 0 100px !important;
    }
    
}


.navbar-collapse {
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    color: rgba(0,0,0,.5);
    border-color: rgba(0,0,0,.1);
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler-icon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAACdGdVrAAAACVBMVEUAAAD///////9zeKVjAAAAAnRSTlMAuLMp9oYAAAAVSURBVAjXY4CCrFVAsBJGkQpobwoAVYgWY5ERw8gAAAAASUVORK5CYII=) no-repeat scroll 50%;
}

ul.navbar-nav {
    display: flex;
    text-align: right;
    flex-direction: row;
    li.menu-item{
        margin-right: 18px;
        a{
            font-size: 22px;            
            padding: 0 0 0 10px;
            line-height: 70px;
            z-index: 9;
            text-decoration: none;
            color: #fff;
        }
        :hover {
            color: #44c2b2;
        }
    }
}



#iq-favorites{
    padding-top: 10%;
    max-height: 100vh;
    height: 100%;
}
.main-content-contaner{    
    margin : 0 auto;
}
.iq-main-header{
    margin : 5% 0;
}

.cycle-title{
    color:#eee; 
    width: 28%;             
    text-align: center; 
    padding-top: 14%;
    padding-bottom: 14%;       
    border-radius: 300px;
    position: relative;
}
.cycle-data1{
    color:#eee; 
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.cycle-data2{
    color:#eee; 
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.cycle-text{
    color:#222; 
    width: 28%;     
    text-align: center;
}
.cycle1{
    border: 2px solid #44c2b2;    
    background: rgba(68, 194, 178, 0.1);
}
.cycle2{
    border: 2px solid #8e9091;
    background: rgba(142, 144, 145, 0.1);
}
.cycle3{
    border: 2px solid #90724b;
    background: rgba(144, 114, 75, 0.1);
}

.cycle1 > h1{
    color : #44c2b2;         
}
.cycle1 > h3{
    color : #44c2b2;         
}
.cycle2 > h1{
    color : #8e9091;
}
.cycle2 > h3{
    color : #8e9091;
}
.cycle3 > h1{
    color : #90724b;
}
.cycle3 > h3{
    color : #90724b;
}

#iq-upcoming-movie{
    padding-top: 10%;
    max-height: 100vh;
    height: 100%;
}
.main-content-contaner{
    padding: 0 5%;
}

.text-icon{
    padding-right: 10px;
}
#iq-suggestede{
    padding-top: 10%;
    max-height: 100vh;
    height: 100%;   
    margin-bottom: 10%;
}
.main-content-contaner{
    padding: 0 5%;    
    margin : 0 auto;
}
.upcoming-contens{
    margin : 0 -10px;
}
.main-title4{
    color:#222;
    margin : 20px 0;
    text-align: left;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2 ;
    -webkit-box-orient: vertical;
}
.sub-content{
    text-align: left;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3 ;
    -webkit-box-orient: vertical;
}
.cycle-title4{
    color:#222; 
    text-align: center; 
    border: 1px solid #eee; 
    padding: 15px;
    background-color: #eee;
    min-height: 237px;
}
.cycle-title5{
    color:#222; 
    text-align: center; 
    border: 1px solid #eee; 
    padding: 15px;
    background-color: #eee;
}
.img-box {
    padding : 10px;
}

.cycle-text{
    color:#222; 
    width: 300px; 
    text-align: center;
}

#iq-topten{
    padding-top: 10%;
    max-height: 100vh;
    height: 100%;
}

.main-content-contaner{
    padding: 0 5%;    
    margin : 0 auto;
}
.main-title{
    color:#222;    
}
.main-title2{
    color:#222;
    margin : 20px 0;
}
.cycle-title2{
    color:#222; 
    text-align: center;     
    padding: 5%;
    margin : 10px;
    position: relative;    
}

@media (max-width: 576px){
    .cycle-title2{
        width: 100%;
    }
}


@media (max-width: 767px){
    .main-title {
        font-size: 1em;
    }
    .main-title2 {
        font-size: 1em;
    }
    .main-title4 {
        font-size: 1em;
    }
}
#contact_footer {
    display: inline-block;
    width: 100%;
    float: left;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background: #191919 !important;
    overflow: hidden;
    .footer-top {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}



.footer-standard .widget ul li {
    display: inline-block;
    padding-right: 30px;
}

.footer-link-title {
    margin-bottom: 20px;
    margin-top: 0;
}

footer.footer-one .footer-top .info-share li {
    margin-right: 12px;
    display: inline-block;
}

footer.footer-one .footer-top .info-share li a {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin-right: 0;
    color: #fff;
    font-size: 17px;
    border-radius: 50%;
    background: rgba(41,41,41,.76);
    transition: all .4s ease 0s;
}

footer.footer-one .footer-top .footer-standard .info-share {
    margin-bottom: 0;
}

footer.footer-one .footer-top .info-share {
    padding-left: 0;
}

@media (max-width: 991px){
    .main-container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }
    img.logo {
        width: 100px;
    }
    .navbar-collapse {
        position: fixed;
        top: 0;
        left: 0;
        padding-bottom: 15px;
        width: 260px;
        height: 100vh;
        background: #191919;
        padding-top: 40px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }
    .navbar-collapse.show {
        left: -15px;
        transition: left .3s ease-in;
        z-index: 999;
    }
    .navbar ul.navbar-nav {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 350px;
        display: inline-block;
        width: 100%;
    }
    .navbar ul {
        width: 100%;
        background: '#fff';
    }
    .navbar ul li {
        display: block;
    }
    .navbar ul li.menu-item a {
        line-height: normal;
        line-height: 10px;
        float: left;
        width: 100%;
        text-align: left;
        padding: 10px 30px;
    }
}

.custom-btn {
    display: inline-block !important;
    padding : 7px 13px !important;
    font-size : 14px !important;
    background-color: inherit !important;
    border : none !important;
    margin-right: 16px;   
    color : #4DBBAA !important; 
    &.active {        
        border-radius: 4px !important;
        background-color: #48C3B1 !important;
        color: #ffffff !important;
    }
}

.regist-btn {
    display: inline-block !important;
    padding : 7px 13px !important;
    font-size : 14px !important;
    background-color: #fff !important;    
    margin-right: 16px;   
    color : #4DBBAA !important; 
    border-radius: 4px !important;
    border : 1px solid #4DBBAA !important;     
}

.to-list-btn {
    padding: 0.4rem 1rem !important;
    border-radius: 4px !important;
    margin-bottom: 31px !important;
    font-size: 14px !important;
    color: #fff !important;
    background: #bdbdbd !important;
}

.container-fluid .breadcrumb-item.active {
    color: #333 !important;
    font-weight: 600 !important;
}

.chart-table{
    text-align: center;
    
    .chart-thead{
        >tr {
            height: 30px;
            >td{
                color: #fff !important;
                background-color: #48c3b1;
                color: #000;
                font-weight: 600;                
                font-size: 16px;
            }
            >th{
                color: #fff !important;
                background-color: #48c3b1;
                color: #000;
                font-weight: 600;                
                font-size: 12px;
            }
        }        
    }

    .chart-tbody{
        >tr:nth-child(even) {            
            height: 48px;
            >td{                
                background-color: #EEFAF8;
                color: #545454;                
                font-size: 12px;
            }           
        }    
        >tr:nth-child(odd) {            
            height: 48px;
            >td{                
                background-color: #fff;
                color: #545454;                
                font-size: 12px;
            }           
        }     
        &.clickable{
            td {
                cursor: pointer;
                &.click-not{
                    cursor: auto;
                }
            }            
        }                   
    }
}

.waver-select-box{
    height: 38px;
    width:150px;
    font-size: 14px;
    padding: 5px 13px;
    background: #FFFFFF;
    color : #333;
    border: 1px solid #BDBDBD;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
}

// .btn-custom {
//     font-size: 16px;
//     padding: 0.375rem 0.75rem;
//     border-radius: 0.25rem;
// }

// .d-flex {
//     .btn {
//         margin-right: 0.8em;
//     }
// }

.waver-pagination-btn-group{

    .left-btn{
        cursor: auto;
        padding: 0.5rem 1rem;
        border: 1px solid #1C2F1A;
        color: #1C2F1A;
        background: #fff;
        &.active{
            border: 1px solid #1C2F1A;
            cursor: pointer;
            color: #0B393E;
            background: #fff
        }
    }

    .right-btn{
        cursor: auto;
        padding: 0.5rem 1rem;
        border: 1px solid #1C2F1A;
        color: #1C2F1A;
        background: #fff;
        &.active{
            border: 1px solid #1C2F1A;
            cursor: pointer;
            color: #0B393E;
            background: #fff
        }
    }

    .center-btn{
        cursor: pointer;
        padding: 0.5rem 1rem;
        border: 1px solid #1C2F1A;
        color: #1C2F1A;
        background: #fff;
        &.active{
            border: 1px solid #1C2F1A;
            cursor: auto;            
            color: #fff;
            background: #1C2F1A;
        }
    }

    .submit-btn{
        cursor: pointer;
        padding: 0.375rem 0.75rem;
        border: 1px solid #4DBBAA;
        border-radius: 0.25rem;
        color: #4DBBAA;
        background: #fff;
        &.active{
            border: 1px solid #BDBDBD;
            cursor: auto;            
            color: #fff;
            background: #4DBBAA;
        }
    }
    .submit-btn:hover {
        color: var(--bs-btn-hover-color);
        text-decoration: none;
        background-color: var(--bs-btn-hover-bg);
        border-color: var(--bs-btn-hover-border-color);
    }
}

.form-control:disabled, .form-control[readonly] {
    background-color: rgba(238, 238, 238, 0.25) !important;
}

.form-control {
    padding: 0.375rem 0.75rem !important;
    border: 1px solid #BDBDBD !important;
    border-radius: 0.375rem !important;
    line-height: 1.5 !important;
    color: #333 !important;
    height: 38px;
}

.waver-download-btn{
    cursor: pointer;
    background : #4DBBAA;
    width: 38px;
    height: 38px;
    margin: 0 auto;
    padding: 10px;
    border-radius: 4px;
    i {
        color : #fff;
    }
}

.form-switch {
  
    .form-check-input {
        width: 2.5em !important;
        margin-left: 0 !important;
        height: 1.1rem;
    }
}

#gmaps-markers{
    position: relative;
    background-color: #fff;
    height: calc(100vh - 305px);
}

.landing-first{
    opacity: 0.5 !important;
    transition: opacity 2s ease;
}

@for $i from 1 through 9 {
    @if ($i == 6) {
        .font-weight-bold {
            font-weight: $i * 100;
        }
    }@else {
        .font-weight-#{$i}00 {
            font-weight: $i * 100;
        }
    }
}

@for $i from 1 through 60 {
    .padding-top-#{$i} {
        padding-top: 1px * $i !important;
    }
    .padding-left-#{$i} {
        padding-left: 1px * $i !important;
    }
    .padding-bottom-#{$i} {
        padding-bottom: 1px * $i !important;
    }
    .padding-right-#{$i} {
        padding-right: 1px * $i !important;
    }
    .margin-top-#{$i} {
        margin-top: 1px * $i !important;
    }
    .margin-left-#{$i} {
        margin-left: 1px * $i !important;
    }
    .margin-bottom-#{$i} {
        margin-bottom: 1px * $i !important;
    }
    .margin-right-#{$i} {
        margin-right: 1px * $i !important;
    }
}

.form-control::placeholder {
    color: #BDBDBD !important;
    font-size: 16px;
}

video#bgvid {
    // position: fixed; left: 50%; top: 50%;
    // min-width: 100%; min-height: 100%;
    // width: auto; height: auto; z-index: -100;
    // background: #333;
    // background-size: cover;    
    // transform:translate(-50%, -50%); 
    width: 50%;
    height: 50%;
    object-fit: cover;
}

.waver-sign-box-header{
    border: 1px solid #bdbdbd;
    border-bottom: none;
    text-align: center;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom : 5px;
    margin : 0 1px 0 0;
    width: 80px;
    display: block;
    word-break : break-word;
}

.waver-sign-box-content{
    border: 1px solid #bdbdbd;
    border-bottom: none;
    text-align: center;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom : 5px;
    margin : 0 1px 0 0;
}

.waver-sign-box-footer{
    border: 1px solid #bdbdbd;
    border-top: none;
    text-align: center;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom : 5px;
    margin : 0 1px 0 0;
}

.waver-faq-tr{
    tr .waver-faq-div{
        border : 1px solid #BDBDBD;
        height : 100%; 
        overflow: hidden;
        border-top : none;
        
        .waver-faq-answer{
            border-top : 1px solid #BDBDBD;
            width: 100%;
            padding: 18px 16px 1px 16px;            
            font-size: 16px;
            background: #fff;
        }

        &.active{
            background: #EEFAF8;
            border: 1px solid #BDBDBD;
            border-top : none;
            color: #4DBBAA;
        }
    }    
    tr:first-child .waver-faq-div{
        border-top-right-radius : 4px;
        border-top-left-radius : 4px;        
        border-top : 1px solid #BDBDBD;        
        &.active{            
            border-top: 1px solid #BDBDBD;
        }
    }
 
    tr:last-child .waver-faq-div{
        border-bottom-right-radius : 4px;
        border-bottom-left-radius : 4px;   
    }        
}

.noMargin {
    p {
        margin: 0;
    }
}

.line-height-100 {
    line-height: normal;
}

.line-height-140 {
    line-height: 140%;
}

.pointer {
    cursor: pointer;
}

.map-custom>div:nth-child(2) {
    height: 48px !important;
}

iframe {
    z-index: -100 !important;
}

.overlay {
    width: 176px;
    height: 112px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 14px;
    border-radius: 12px;
    padding: 12px;
    white-space: normal;
    position: relative;
}

.x-button {
    right: 6px;
    top: 6px;
    width: 18px;
    height: 24px;
    opacity: 0.4;
    font-weight: bold;
}
.x-button:hover {
    opacity: 1;
}

.waver-select-box {
    height: 38px;
    width:80px;
    font-size: 14px;
    padding: 5px 13px;
    background: #FFFFFF;
    color : #333;
    border: 1px solid #BDBDBD;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    margin-right: 8px;
}

.bg-dark-ash {
    background: linear-gradient(#0B393E, #1C2F1A);
}

// .marker-post {
//     // width: ;
// }

// .radio-toolbar input[type="radio"] {
//     opacity: 0;
//     position: fixed;
//     width: 0;
//   }
//   .radio-toolbar label {
//     display: inline-block;
//     background-color: $gray-300;
//     cursor: pointer;
//     padding: 5px 10px;
//     font-size: 14px;
//     border-radius: 4px;
//   }
//   .radio-toolbar input[type="radio"]:checked + label {
//     background-color: $primary;
//     border: none;
//     color: $white;
//   }
  
//   #radio-title {
//     margin-bottom: 5px;
//   }
  
  