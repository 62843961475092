/* =============
   General
============= */

html {
  position: relative;
  min-height: 100%;
}

.h1,.h2,.h3,.h4,.h5,.h6,
h1,h2,h3,h4,h5,h6 {
  font-family: $font-family-secondary;
  font-weight: $font-weight-semibold;
}


p{
  line-height: 1.7;
}

a {
  text-decoration: none !important;
}

label {
  font-weight: 200;
}

legend {
  font-size: 16px;
  color: black;
  margin-bottom: 6px;
}

.form-group>label {
  font-size: 16px;
  color: black;
  margin-bottom: 6px;
  font-weight: $fw-medium;
}

.profilewrap label {
  color: #9a9a9a;
}

.allbaroRegist legend {
  font-size: 15px;
  padding: 12px 0 0 10px;
}

b,
strong {
  font-weight: $fw-medium;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid $gray-300;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid $gray-300;
  text-align: right;
}

.noMargin {
  margin: 0;
}

.loginInput label{
  font-size: 14px;
  font-weight: 600;
  color: #5b626b;
}